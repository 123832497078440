import Vue from 'vue'
import { 
  Button,
  Tabs,
  TabPane,
  Form,
  FormItem,
  Input,
  Row,
  Col,
  Checkbox,
  Message,
  MessageBox,
  Menu,
  Submenu,
  MenuItemGroup,
  MenuItem,
  DatePicker,
  Table,
  TableColumn,
  Upload,
  Image,
  Pagination
} from 'element-ui'

Vue.prototype.$message = Message;
Vue.prototype.$MessageBox = MessageBox;
Vue.use(Button)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(Row)
Vue.use(Col)
Vue.use(Checkbox)
Vue.use(Menu)
Vue.use(Submenu)
Vue.use(MenuItemGroup)
Vue.use(MenuItem)
Vue.use(DatePicker)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Upload)
Vue.use(Image)
Vue.use(Pagination)
