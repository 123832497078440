
































import { Component, Vue, Ref } from "vue-property-decorator";
import { ElForm } from "element-ui/types/form";
import { signIn } from "../api/index";
@Component({
  name: "J_SigInByPassword",
  // 子组件
  components: {},
})
export default class J_SigInByPassword extends Vue {
  // 属性
  private loginFormData = {
    email: "",
    password: "",
  };
  // 提交信息
  @Ref() readonly loginFormRef!: ElForm;
  private submitForm() {
    try {
      this.loginFormRef.validate((flag) => {
        if (flag) {
          signIn(this.loginFormData)
            .then((data: any) => {
              if (data.code == 200) {
                sessionStorage.setItem("user_id", data.data._id);
                this.$router.push("/home");
              } else {
                (this as any).$message.error(data.msg);
              }
            })
            .catch((error: any) => {
              (this as any).$message.error(error.message);
            });
        } else {
          return false;
        }
      });
    } catch (error) {
      throw new Error(error);
    }
  }
  // 消除信息
  public clearFormToSignIn() {
    this.loginFormRef.resetFields();
  }

  // * 自定义校验规则
  // 校验账户
  private validateAccount = (rule: any, value: string, callback: any) => {
    try {
      // 手机正则
      const phoneReg = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
      if (!value) {
        // ^ 无值提示
        callback(new Error("请填写邮箱账号!"));
      } else if (phoneReg.test(value)) {
        callback();
      } else {
        callback(new Error("邮箱格式不正确"));
      }
    } catch (error) {
      throw new Error(error);
    }
  };
  // 校验密码
  private validatePass = (rule: any, value: string, callback: any) => {
    const reg =
      /^(?![A-Za-z]+$)(?![A-Z\d]+$)(?![A-Z\W]+$)(?![a-z\d]+$)(?![a-z\W]+$)(?![\d\W]+$)\S{8,}$/;
    if (!value) {
      callback(new Error("请填写密码"));
    } else if (value.length < 8) {
      callback(new Error("密码至少是8位"));
    } else if (!reg.test(value)) {
      callback(new Error("密码必须包含字母数字和特殊符号"));
    } else {
      callback();
    }
  };
  // 校验规则
  loginRules = {
    email: [{ validator: this.validateAccount, trigger: "blur" }],
    password: [{ validator: this.validatePass, trigger: "blur" }],
  };
}
