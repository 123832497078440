import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Index from '../views/V_Index.vue'


Vue.use(VueRouter)

// > 添加两行代码,消除重复路由的报错
const originalPush: any = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location: any) {
  try {
    return originalPush.call(this, location)
  } catch (error) {
    error
  }
}

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'index',
    component: Index
  },
  {
    path: '/home',
    name: 'home',
    redirect: '/myUser',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/V_Home.vue'),
    children: [
      // 当 /user/:id 匹配成功，
      // UserHome 会被渲染在 User 的 <router-view> 中
      { path: '/myUser', name: 'myUser', component: () => import(/* webpackChunkName: "about" */ '../views/subView/MyUser.vue') },
      { path: '/addUser', name: 'addUser', component: () => import(/* webpackChunkName: "about" */ '../views/subView/AddUser.vue') },
      { path: '/newNews', name: 'NewNews', component: () => import(/* webpackChunkName: "about" */ '../views/subView/NewNews.vue') },
      { path: '/upload', name: 'upload', component: () => import(/* webpackChunkName: "about" */ '../views/subView/Upload.vue') },
      { path: '/price', name: 'Price', component: () => import(/* webpackChunkName: "about" */ '../views/subView/Price.vue') },
      {
        path: '/picture', redirect: '/homeCarousel', name: 'picture', component: () => import(/* webpackChunkName: "about" */ '../views/subView/Picture.vue'),
        children: [
          // 当 /user/:id 匹配成功，
          // UserHome 会被渲染在 User 的 <router-view> 中
          { path: '/homeCarousel', name: 'homeCarousel', component: () => import(/* webpackChunkName: "about" */ '../views/subView/subImage/HomeCarousel.vue') },
          { path: '/QRcode', name: 'QRcode', component: () => import(/* webpackChunkName: "about" */ '../views/subView/subImage/QRcode.vue') },
          { path: '/advertCarousel', name: 'advertCarousel', component: () => import(/* webpackChunkName: "about" */ '../views/subView/subImage/AdvertCarousel.vue') },
          { path: '/personalCover', name: 'personalCover', component: () => import(/* webpackChunkName: "about" */ '../views/subView/subImage/PersonalCover.vue') },
          { path: '/homeItem', name: 'homeItem', component: () => import(/* webpackChunkName: "about" */ '../views/subView/subImage/homeItem.vue') },
        ]
      },
      { path: '/integral', name: 'Integral', component: () => import(/* webpackChunkName: "about" */ '../views/subView/subUser/Integral.vue') },
      { path: '/order', name: 'Order', component: () => import(/* webpackChunkName: "about" */ '../views/subView/subUser/Order.vue') },
    ]
  }
]

const router = new VueRouter({
  routes
})
// 路由守卫
router.beforeEach((to, from, next) => {
  if (to.fullPath == '/') {
    next()
  } else {
    const user_id: any = sessionStorage.getItem('user_id')
    if (user_id.length > 10) {
      next()
    }else{
      router.push('/')
    }
  }
})
export default router
