import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user_id: '',
    images: [],
    uploadImgUrl: 'https://www.knowmap.cn/api/update/picture',
  },
  getters: {
    getUserId: state => {
      return state.user_id
    },
    getImages: state => {
      return state.images
    },
    getUploadImgUrl: state => {
      return state.uploadImgUrl
    }
  },
  mutations: {
    setUserId(state, id) {
      state.user_id = id
    },
    setImages(state, images) {
      state.images = images
    }
  },
  actions: {
  },
  modules: {
  }
})
