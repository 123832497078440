


















import { Component, Vue, Ref } from "vue-property-decorator";
import J_SignInByPassword from "@/components/J_SignInByPassword.vue";

@Component({
  name: "V_Index",
  components: {
    J_SignInByPassword,
  },
})
export default class Index extends Vue {
  // @Ref() readonly J_SignInByPassword!: J_SignInByPassword;
}
