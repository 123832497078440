import Network from './network';
export const signIn = (data: {}) => Network.post('/signIn', data);
export const signOut = (data: {}) => Network.post('/auth/signOut', data);

// >> 用户信息
// > 查询用户信息api
export const findUserInfo = (data: {}) => Network.post('/auth/userInfo/find', data);
// > 条件查询
export const conditionQuery = (data: {}) => Network.post('/auth/userInfo/conditionQuery', data);
export const del_userInfo = (data: {}) => Network.post('/auth/userInfo/del', data);
export const add_userInfo = (data: {}) => Network.post('/auth/userInfo/add', data);
export const update_userInfo = (data: {}) => Network.post('/auth/userInfo/update', data);
// 过期提醒
export const expirationReminder_userInfo = (data: {}) => Network.post('/auth/userInfo/expirationReminder', data);

// >> 订单详情

export const find_orderInfo = (data: {}) => Network.post('/auth/orderInfo/find', data);
export const add_orderInfo = (data: {}) => Network.post('/auth/orderInfo/add', data);
export const del_orderInfo = (data: {}) => Network.post('/auth/orderInfo/del', data);
export const update_orderInfo = (data: {}) => Network.post('/auth/orderInfo/update', data);

// >> 积分详情
export const find_integral = (data: {}) => Network.post('/auth/integral/find', data);
export const add_integral = (data: {}) => Network.post('/auth/integral/add', data);
export const del_integral = (data: {}) => Network.post('/auth/integral/del', data);
export const update_integral = (data: {}) => Network.post('/auth/integral/update', data);

// >> 新增订单
export const find_pending = (data: {}) => Network.post('/auth/pending/find', data);
export const read_pending = (data: {}) => Network.post('/auth/pending/read', data);

// > 获取全部图片数据
export const findAll_picture = (data: {}) => Network.post('/auth/picture/findAll', data);
// > 想服务发送新的轮播图顺序
export const imageOrderChange = (data: {}) => Network.post('/auth/imageOrderChange', data);

// > Excel 导出列表
export const exportExcel = (data: {}) => Network.post('/auth/exportExcel', data);
export const exportExcel2 = (data: {}) => Network.get('/exportExcel', data);
// > Excel 导入列表
export const uploadExcel = (data: {}) => Network.post('/uploadExcel', data);


// >下载连接
export const find_downloadLink = (data: {}) => Network.post('/auth/downloadLink/find', data);
export const add_downloadLink = (data: {}) => Network.post('/auth/downloadLink/add', data);
export const del_downloadLink = (data: {}) => Network.post('/auth/downloadLink/del', data);

// > 价格管理 
export const find_price = (data: {}) => Network.post('/auth/price/find', data);
export const update_price = (data: {}) => Network.post('/auth/price/update', data);